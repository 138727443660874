.layout {
  height: 500px;
  background-color: #fff;

  &__image {
    width: 100%;
    height: 300px;
    object-fit: cover;
    padding: 0;
    margin-top: 10px;
  }

  &__title {
    font-weight: 600;
    margin-top: 10px;
    padding: 0;
    margin: 0;
  }

  &__types {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;

    p {
      font-size: 11px;
      font-weight: 600;
      padding: 0;
      margin: 0;
    }
  }

  &__footer {
    margin-top: 10px;
    font-size: 11px;
    padding: 30px;
    margin: 0;
    font-style: italic;
    color: gray;
  }
}
