.img-wrapper {
  position: relative;
}

.img-responsive {
  width: 100%;
}

.img-overlay-m,
.img-overlay-b {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
}

.img-overlay-m:before {
  content: ' ';
  display: block;
  /* adjust 'height' to position overlay content vertically */
  height: 40%;
}

/* .img-overlay-b:before {
  content: ' ';
  display: block;
  height: 90%;
} */

.row-bottom {
  position: absolute;
  bottom: 5px;
}

.watermark {
  font-weight: 800;
  font-size: 96px;
  line-height: 113px;
  color: rgba(255, 255, 255, 0.58);
  transform: rotate(-26.63deg);
}

.check-box {
  background-color: #eaeaea;
  border-radius: 25px;
}

.modal-container {
  background-color: #dddddd;
}

.modal-header {
  background-color: #dddddd;
  border: #183e4b solid 1px;
}

.modal-body {
  border: #183e4b solid 1px;
  background-color: #dddddd;
}

.reduced {
  opacity: 0.5;
  color: #eaeaea;
  font-weight: 100;
  font-style: italic;
}

.size-options-text {
  color: white;
}

.price__text {
  font-family: Raleway;
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.label__text {
  color: #000;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

input[type='radio'] {
  display: none;
}

.gray__card {
  display: inline-flex;
  padding: 19px 92px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 25px;
  background: #eaeaea;
  margin-bottom: 15px;
}

.flex__button .custom__btn {
  display: inline-flex;
  padding: 10px 23px;
  justify-content: center;
  align-items: center;
  height: unset;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 10px !important;
  background: #d74a49;
  border-color: #000000;
  color: #fff;
  font-family: Raleway;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 0;
}

.custom__btn {
  display: inline-flex;
  height: 36px;
  padding: 6px 23px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 25px !important;
  background: #d74a49;
  border-color: #d74a49;
  color: #fff;
  font-family: Raleway;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 36px;
}

.custom__card__2 {
  background: #fff;
  box-shadow: 0px -2px 6px 0px rgba(0, 0, 0, 0.25);
  padding: 50px 20px;
  border-radius: 4px;
  margin-bottom: 50px;
}

.custom__card__2 .price__text {
  text-align: center;
}

.flex__button {
  display: flex;
  margin-top: 36px;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.image__checkout {
  text-align: center;
  background: #eaeaea;
  /*border: 2px solid #000000;*/
  padding: 30px 20px;
  border-radius: 0 0 8px 8px;
  /*margin-bottom: 50px;*/
}

.image__checkout h2 {
  font-weight: bold;
  font-size: 4rem;
}

.image__checkout p {
  margin-bottom: 0;
  font-weight: 550;
  font-size: 16px;
}

.resolution {
  /*margin-top: 20px;*/
}

.resolution h3 {
  font-size: 21px;
}

.resolution .resolution__title {
  font-size: 35px;
}

.MuiCardHeader-root {
  border: 5px solid #8d8d8d;
  border-bottom: none;
  border-radius: 0;
}

.MuiCardMedia-media {
  border: 5px solid #8d8d8d !important;
  border-top: none !important;
  border-bottom: none !important;
  border-radius: 0 !important;
}

.MuiCardContent-root {
  border: 5px solid #8d8d8d;
  border-top: none;
}

.checkbox__section {
  display: flex;
  justify-content: center;
}

@media (max-width: 576px) {
  .flex__button {
    flex-direction: column;
  }

  .custom__card {
    margin-top: 30px;
  }

  .image__checkout {
    margin-bottom: 0;
  }


}

.terms__label {
  font-size: 12px !important;
  font-weight: 600;
}

.terms__label a {
  color: #000000;
}

.card__header {
  padding-left: 19px;
  padding-top: 30px;
  border-top: 2px solid rgba(211, 211, 211, 0.1);
}

.card__header__top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.card__header__top .icons {
  display: flex;
  align-items: center;
  gap: 10px;

  & svg {
    width: 25px;
    height: 25px;
    color: #d74a49;
    cursor: pointer;
  }
}

.card__header__top .icons .icon {
  position: relative;
  cursor: pointer;
}

.card__header__top .icons .icon img {
  width: 25px;
}

.card__header__top .icons .icon .share__dropdown {
  position: absolute;
  display: flex;
  padding: 33px 38px 33px 21px;
  flex-shrink: 0;
  border-radius: 25px;
  background: #eaeaea;
  top: 50px;
  right: 0;
  flex-direction: column;
  gap: 23px;
  width: 300px;
}

/*.card__header__top .icons .icon .share__dropdown .item:hover {*/
/*    background-color: #183E4B;*/
/*    color: #ffffff;*/
/*}*/

.card__header__top .icons .icon .share__dropdown .item {
  display: flex;
  align-items: center;
  gap: 20px;
  cursor: pointer;
  padding: 10px 0;
  border-radius: 5px;
}

.card__header__top .icons .icon .share__dropdown .item img {
  width: 22px;
  height: 17px;
}

.card__header__top .icons .icon .share__dropdown .item p {
  color: #183e4b;
  font-family: Raleway;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
}

.related_image_section {
  /*background: red;*/
}
