.masonic {
  padding: 8px;
  width: 100%;
  max-width: 960px;
  margin: 163px auto;
}

.masonic-container {
    min-height: 100vh;
    width: 100%;
}

.select-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    background-color: #EAEAEA;
}

.bg-banner-info {
    background-color: #183E4B;
    color: #DDDDDD;
    font-size: large;
    width: 100%;
}
.bg-banner-info2 {
    background-color: #8BA0A4;
    color: #DDDDDD;
    font-size: large;
    border-radius: 10px;
    width: 80%;
    margin-top: 10px;
    margin-bottom: 10px;
}
.register-text {
    color: white;
}

.imageBox {
    margin-top: 50px;
    &__heading {
      font-size: 32px;
      font-weight: 600;
      line-height: 37.57px;
      font: 'Raleway';
      text-align: center;
    }

    &__wrapper {
      margin-top: 50px;
      margin-bottom: 50px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 42px;

      @media screen and (max-width: 1080px) {
        display: flex;
        flex-direction: column;
      }

      &__box {
        display: flex;
        flex-direction: column;
        gap: 42px;
        width: calc(25% - 34.5px);

        @media screen and (max-width: 1080px) {
          width: 100%;
          flex-direction: column;
          gap: 34.5px;
        }

        @media screen and (max-width: 550px) {
          width: 100%;
          flex-direction: column;
          gap: 17px;
        }
      }

      &__image {
        width: 100%;
        height: auto;
        border-radius: 4px;
        object-fit: cover;
        cursor: pointer;

        @media screen and (max-width: 1080px) {
          width: 100%;
          object-fit: cover;
        }
      }

      &__button {
        box-sizing: border-box;
        width: 250px;
        height: 36px;
        outline: none;
        border: none;
        border-radius: 25px;
        padding: 6px 13px 6px 13px;
        background-color: #d74a49;
        color: white;
        font-weight: 600px;
        font-size: 14px;
        line-height: 18.78px;
        opacity: 1;

       
        @media screen and (max-width: 650px) {
          font-size: 12px;
          width: 200px;
          padding: 3px 11.5px 3px 11.5px;
        }
      }

      &__button:disabled {
        opacity: 0.5;
      }
    }
  }
