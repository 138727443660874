input[type="range"] {
    -webkit-appearance: none !important;
    width: 100%;
    height: 3px;
    background: #d74a4a;
    border: none;
    outline: none;
}
input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none !important;
    width: 25px;
    height:25px;
    background: #d74a4a;
    border: 2px solid #d74a4a;
    border-radius: 50%;
    cursor: pointer;
}
input[type="range"]::-webkit-slider-thumb:hover {
    background: #d74a4a;
}