
.show-on-hover {
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.show-on-hover-no-click {
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.show-on-hover:hover {
  opacity: 1;
  cursor: pointer;
}

.show-on-hover-no-click:hover {
  opacity: 1;
}

.MuiImageListItemBar-title {
  vertical-align: text-top !important;
  white-space: unset !important;
}

.image__list {
  position: relative;

  &:hover .hover__section {
    transition: .3s ease-in-out;
    transform: scale(1);
  }

  .tile2__image {
    background: rgba(0, 0, 0, 0.30);
  }

  .hover__section {
    overflow: hidden;
    display: flex;
    background: rgba(0, 0, 0, 0.30);
    padding: 27px 24px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    justify-content: space-between;
    flex-direction: column;
    transition: .3s ease-in-out;
    transform: scale(0);

    .header {
      display: flex;
      justify-content: space-between;

      .right {
        display: flex;
        gap: 15px;
        align-items: flex-start;
        justify-content: center;
        position: relative;

        img {
          width: 24px;
          height: 21px;
          cursor: pointer;
        }
      }
    }

    .footer {
      display: flex;
      justify-content: flex-end;

      .title {
        cursor: pointer;

        h3 {
          color: #FFF;
          font-family: Roboto;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
      }

      .btn__button {
        border-radius: 25px;
        background: #EAEAEA;
        color: #183E4B;
        font-family: Raleway;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        padding: 9px 24px;
        cursor: pointer;
      }
    }
  }
}