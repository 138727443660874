.payment__signup {
  padding: 50px 0;
  width: 60%;
  margin: 0 auto;

  .header {
    text-align: center;
    margin-bottom: 100px;

    .title {
      font-size: 40px;
      font-weight: 600;
      color: #013a01;
      margin: 0;
    }

    .sub__title {
      font-size: 60px;
      font-weight: bold;
      color: #000000;
      margin: 0;
    }

    .text {
      font-size: 20px;
      margin: 0;
    }
  }

  .payment__payment {
    .authorize {
      border: 1px solid gray;
      border-radius: 35px;
      padding: 30px;

      .authorize__logo {
        display: flex;
        justify-content: space-between;

        .left {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 17.51px;

          p {
            font-size: 20px;
            margin-bottom: 0;
          }

          img {
            width: 64.696px;
            height: 39.449px;
          }
        }

        .right {

        }
      }
    }

    .paypal {
      border: 1px solid gray;
      border-radius: 35px;
      padding: 30px 30px 0 30px;
      width: 80%;
    }
  }
}

.credit__card__form {
  min-width: 100%;
}

.buttons-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.error__section {
  border: 2px solid #d74a49;
  padding: 50px;
  font-size: 20px;
  width: 80%;
  margin: 50px auto 0 auto;
  border-radius: 30px;
  color: #000000;
  text-align: center;
  background: rgba(215, 74, 73, 0.15);

  .error__text {
    color: #d74a49;
    font-weight: 600;
  }
}

.input__label {
  margin-top: 20px;
  margin-bottom: 10px;
  color: #183E4B;
  font-family: Raleway;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
}

.authoriseForm {
  display: flex;
  gap: 5px;
}

.paypalForm {
  display: flex;
  gap: 5px;
  margin-top: 50px;
}

.rounded-checkbox {
  border-radius: 2rem !important;
}

.rounded__input__text {
  width: 100%;
  height: 2.8rem;
  background: #f5f5f5;
  outline: none;
  border: 1px solid #183E4B;
  border-radius: 1.625rem;
  padding: 0 3.5rem 0 1.5rem;
  font-size: 1rem;
}