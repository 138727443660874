.border-primary {
  border-color: #183E4B !important;
}
// center image with slight y offset of 20 pixels
.bg-search-hero {
  color: #EAEAEA;
  background-image: url('https://storage.googleapis.com/smartai-stockimage-dev-storage-watermark-na/Science%20Fiction/20231119/d40c9b0b-0f0b-461e-9034-990f44a3d3de.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}
.multi-dropdown {
  font-weight: 800;
  /* border: 1px solid rgba(0, 0, 0, .15); */
}

.search__result {
  text-align: center;
}

.recent__search {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  margin-top: 20px;

  .tag {
    border: 1px solid gray;
    border-radius: 50px;
    padding: 10px 20px;
    cursor: pointer;
    gap: 10px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 18px;
      height: 18px;
    }
  }
}

.filter {
  text-align: center;
}