@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.custom__card {
    background: #FFF;
    border: 2px solid #959595;
    width: 1577px;

    flex-shrink: 0;
    margin-bottom: 45px;
    position: relative;
    border-radius: 4px;
}

.custom__card .badge {
    display: flex;
    font-size: 12pt;
    font-weight: 500;
    width: 160px;
    height: 25px;
    padding: 10px 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 45px;
    background: #183E4B;
    position: absolute;
    right: 30px;
    top: -10px;
}
.custom__card .leftbadge {
    display: flex;
    width: 160px;
    height: 25px;
    padding: 10px 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 45px;
    background: #183E4B;
    color: #E9E9E9;
    position: absolute;
    left: 30px;
    top: -10px;
}
.custom__card .header {
    padding: 23px;
    text-align: center;
}

.custom__card .header .card__title {
    color: #183E4B;
    font-family: Raleway;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.custom__card .body {
    /*width: 577px;*/
    /*height: 95px;*/
    flex-shrink: 0;
    background: #E9E9E9;
}

.custom__card .body .tabs {
    /*display: grid;*/
    /*grid-template-columns: repeat(auto-fit, minmax(30px, 1fr));*/
    /*grid-gap: 25px;*/
    display: flex;
    justify-content: center;
    align-items: flex-end;
    gap: 12px;
}

.custom__card .body .tab {
    /*background-color: red;*/
    padding: 5px;
    position: relative;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.custom__card .body .tab.active ::after {
    content: "";
    height: 6px;
    position: absolute;
    background: #183E4B;
    width: 100%;
    bottom: 0;
    left: 0;
}

.custom__card .body .tab h5 {
    color: #000;
    text-align: center;
    font-family: Raleway;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.custom__card .body .tab .icon {
    text-align: center;
    margin-bottom: 10px;
}

.custom__card .main__body {
    margin-top: 0px;
    padding: 0 0px;
}

.custom__card .main__body .badges__card {
    margin-top: 36px;
}

.custom__card .main__body .badges__card .title {
    color: #000;
    text-align: center;
    font-family: Roboto;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.custom__card .main__body .badges__card .custom__badges {
    display: flex;
    width: 543px;
    justify-content: center;
    align-items: center;
    align-content: center;
    gap: 10px;
    flex-shrink: 0;
    flex-wrap: wrap;
    margin-top: 28px;
}

.custom__card .main__body .badges__card .custom__badge {
    display: flex;
    width: 161px;
    height: 40px;
    padding: 16px 0px;
    justify-content: center;
    align-items: center;
    gap: 36px;
    flex-shrink: 0;
    border-radius: 25px;
    border: 1px solid #183E4B;
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    cursor: pointer;
    transition: .3s ease-in-out;
}

.custom__card .main__body .badges__card .custom__badge.selected,
.custom__card .main__body .badges__card .custom__badge:hover {
    border: 2px solid #D75D49;
}

.custom__btn {
    display: inline-flex;
    height: 36px;
    padding: 6px 23px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 25px !important;
    background: #D74A49;
    border-color: #D74A49;
    color: #FFF;
    font-family: Raleway;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 36px;
}

.badge__button {
    margin-top: 80px;
}

.frames__area {
    width: 70%;
    margin: 0 auto;
}

.frames__area .frames {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    grid-gap: 25px;
}

.frames__area .frames .frame {
    display: flex;
    width: 140px;
    height: 194px;
    padding: 21px 15px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border: 3px solid transparent;
    cursor: pointer;
    transition: .3s ease-in-out;
}

.frames__area .frames .frame.selected,
.frames__area .frames .frame:hover {
    border: 3px solid #D74A49;
}

.frames__area .frames .frame img.frame_1 {
    border: 1px solid lightgray;
    background-color: #ffffff;
    padding: 5px;
}

.frames__area .frames .frame img.frame_2 {
    border: 7px solid #000;
}

.frames__area .frames .frame img.frame_3 {
    border-top: 12px solid #000;
    border-bottom: 12px solid #000;
}

.frames__area .frames .frame img.frame_4 {
    border-radius: 50%;
}

.frames__area .frames .frame img {
    width: 111px;
    height: 111px;
}

.frames__area .frames .frame h6 {
    color: #000;
    font-family: Raleway;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 16px;
}

.story .custom__btn {
    display: block;
    margin: 20px auto;
}

.story .textarea {
    height: 322px;
    padding: 40px;
    flex-shrink: 0;
    background: linear-gradient(180deg, rgba(243, 243, 243, 0.00) 0%, #F3F3F3 70.19%);
    text-align: justify;
    overflow: auto;
    margin-top: 44px;
}

@media (max-width: 576px) {
    .custom__card .body .tabs {
        display: block;
    }

    .custom__card {
        height: auto;
        width: 100%;
    }

    .custom__card .main__body .badges__card .custom__badges {
        width: auto;
    }

    .frames__area .frames {
        display: block;
    }

    .frames__area .frames .frame {
        width: unset;
        margin-bottom: 20px;
    }

    .custom__btn {
        margin-top: 0;
        height: unset !important;
    }
}

.socials {
    background: #E9E9E9;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-radius: 50px;
    gap: 10px;
    position: absolute;
    right: 0;
    top: 35px;
}

.socials .copy {
    background: rgba(215, 74, 73, 0.22);
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.socials .copy img {
    width: 16px;
    height: 16px;
}