.layout {
  padding: 69px;

  @media screen and (max-width: 1550px) {
    padding: 34.5px;
  }

  @media screen and (max-width: 550px) {
    padding: 17px;
  }

  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 35px;

    @media screen and (max-width: 1080px) {
      display: flex;
      flex-direction: column;
    }

    &__box {
      width: 50%;
      // height: 717px;
      overflow: hidden;

      @media screen and (max-width: 1080px) {
        width: 100%;
        // height: 617px;
      }

      &__image {
        // width: 682px;
        // height: 682px;
        width: 100%;
        height: auto;
        
        // height: 100%;
        object-fit: cover;
        border-radius: 2px;
      }
    }

    &__detail {
      width: 50%;

      @media screen and (max-width: 1080px) {
        width: 100%;
      }

      &__actions {
        margin-top: 50px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media screen and (max-width: 490px) {
          display: flex;
          flex-direction: column;
        }

        &__button {
          box-sizing: border-box;
          width: 256px;
          height: 36px;
          outline: none;
          border: none;
          border-radius: 25px;
          padding: 6px 23px 6px 23px;
          background-color: #d74a49;
          color: white;
          font-weight: 600px;
          font-size: 16px;
          line-height: 18.78px;
          opacity: 1;

          @media screen and (max-width: 1350px) {
            font-size: 12px;
            width: 200px;
            padding: 3px 11.5px 3px 11.5px;
          }

          @media screen and (max-width: 1080px) {
            font-size: 16px;
            width: 256px;
            padding: 6px 23px 6px 23px;
          }

          @media screen and (max-width: 650px) {
            font-size: 12px;
            width: 200px;
            padding: 3px 11.5px 3px 11.5px;
          }
        }

        &__button:disabled {
          opacity: 0.5;
        }
      }
    }
  }

  .imageBox {
    margin-top: 50px;
    &__heading {
      font-size: 32px;
      font-weight: 600;
      line-height: 37.57px;
      font: 'Raleway';
      text-align: center;
    }

    &__wrapper {
      margin-top: 50px;
      margin-bottom: 50px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 42px;

      @media screen and (max-width: 1080px) {
        display: flex;
        flex-direction: column;
      }

      &__box {
        display: flex;
        flex-direction: column;
        gap: 42px;
        width: calc(25% - 34.5px);

        @media screen and (max-width: 1080px) {
          width: 100%;
          flex-direction: column;
          gap: 34.5px;
        }

        @media screen and (max-width: 550px) {
          width: 100%;
          flex-direction: column;
          gap: 17px;
        }
      }

      &__image {
        width: 100%;
        height: auto;
        border-radius: 4px;
        object-fit: cover;
        cursor: pointer;

        @media screen and (max-width: 1080px) {
          width: 100%;
          object-fit: cover;
        }
      }

      &__button {
        box-sizing: border-box;
        width: 250px;
        height: 36px;
        outline: none;
        border: none;
        border-radius: 25px;
        padding: 6px 13px 6px 13px;
        background-color: #d74a49;
        color: white;
        font-weight: 600px;
        font-size: 14px;
        line-height: 18.78px;
        opacity: 1;

        // @media screen and (max-width: 1350px) {
        //   font-size: 12px;
        //   width: 200px;
        //   padding: 3px 11.5px 3px 11.5px;
        // }

        // @media screen and (max-width: 1080px) {
        //   font-size: 16px;
        //   width: 256px;
        //   padding: 6px 23px 6px 23px;
        // }

        @media screen and (max-width: 650px) {
          font-size: 12px;
          width: 200px;
          padding: 3px 11.5px 3px 11.5px;
        }
      }

      &__button:disabled {
        opacity: 0.5;
      }
    }
  }

  .batchBox {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;

    @media screen and (max-width: 650px) {
      margin-bottom: 35px;
    }

    &__container {
      width: 450px;
      height: 179px;
      border: 1px solid #183e4b;
      border-radius: 10px;

      @media screen and (max-width: 650px) {
        width: 100%;
      }

      display: flex;
      flex-direction: column;
      gap: 5px;
      justify-content: center;
      align-items: center;

      &__text {
        font-family: 'Raleway';
        font-weight: 600;
        font-size: 36px;
        line-height: 42.26px;
        text-align: center;
        width: 352px;

        @media screen and (max-width: 650px) {
          font-size: 26px;
          line-height: 30px;
        }
      }

      &__button {
        box-sizing: border-box;
        width: 276px;
        height: 36px;
        outline: none;
        border: none;
        border-radius: 25px;
        padding: 6px 23px 6px 23px;
        background-color: #d74a49;
        color: white;
        font-weight: 600px;
        font-size: 16px;
        line-height: 18.78px;
        opacity: 1;

        @media screen and (max-width: 650px) {
          font-size: 12px;
          width: 226px;
          padding: 3px 11.5px 3px 11.5px;
        }
      }

      &__button:disabled {
        opacity: 0.5;
      }
    }
  }
}

.inputTerms {
  margin: 0;
  font-size: 16px;
  color: #183e4b;
  font-weight: 600;

  @media screen and (max-width: 1350px) {
    font-size: 12px;
  }

  @media screen and (max-width: 1080px) {
    font-size: 16px;
  }

  @media screen and (max-width: 650px) {
    font-size: 12px;
  }
}
