.complete__page {
  padding: 100px 0;
}

.icon {
  text-align: center;
}

.text__content {
  text-align: center;
  margin-top: 50px;

  .main__title {
    color: #000;
    text-align: center;
    font-family: Raleway;
    font-size: 38px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .main__title__2 {
    color: #183E4B;
    font-family: Raleway;
    font-size: 38px;
    font-style: italic;
    font-weight: 300;
    line-height: normal;

    strong {
      color: #183E4B;
      font-family: Raleway;
      font-size: 38px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }

  .info__text {
    color: #000;
    font-family: Raleway;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 20px;
  }
}

.buttons {
  text-align: center;
  margin-top: 100px;

  a {
    display: inline-flex;
    padding: 10px 23px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 25px;
    background: #D74A49;
    color: #FFF;
    font-family: Raleway;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-decoration: none;
  }
}