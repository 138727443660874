.terms__label {
  font-size: 15px;
  margin-top: 10px;
  margin-left: 5px;

  a {
    color: #000000;
  }
}

.free__signup__form {
  background: #ffffff;
  padding: 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.87);
  border-radius: 10px;
  position: relative;

  &.disabled {
    &::before {
      background-color: #ffffff80;
      width: 100%;
      height: 100%;
      content: "";
      position: absolute;
      z-index: 1000;
      top: 0;
      left: 0;
    }
  }
}

.checkbox {
  text-align: center;
}

.free__signup li {
  list-style: circle;
}