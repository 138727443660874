.social__icon {
  position: relative;
  width: 30px;
  height: 30px;
  cursor: pointer;
  color: #183e4b;
}

.socials {
  background: #e9e9e9;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 50px;
  gap: 10px;
  position: absolute;
  right: 0;
  top: 35px;
}

.socials .copy {
  background: rgba(215, 74, 73, 0.22);
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.socials .copy img {
  width: 16px;
  height: 16px;
}
