.bg-about-hero {
  color: #EAEAEA;
  background-image: url('https://storage.googleapis.com/smartai-stockimage-dev-storage-watermark-na/Science%20Fiction/20231119/d40c9b0b-0f0b-461e-9034-990f44a3d3de.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;

}

.masonic {
  padding: 8px;
  width: 100%;
  max-width: 960px;
  margin: 163px auto;
}

.masonic-container {
    min-height: 100vh;
    width: 100%;
}
