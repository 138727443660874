* {
  font-family: "Raleway";
  font-style: normal;
}

/* a {
  color: inherit;
  text-decoration: none;
} */

.bg-navbar {
  min-height: 100px;
  min-width: 400px;
  width: 100%;
  background-color: #183e4b;
}

.navbar-container {
  height: 100%;
  padding: 0px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navbar-logo {
  color: white;
  font-style: bold;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
}

.navbar-company {
  color: white;
  font-style: italic;
  font-weight: 80;
  font-size: 24px;
}

.navbar-logo2 {
  color: white;
  font-style: bold;
  font-weight: 800;
  font-size: 36px;
  line-height: 42px;
}

.navbar-company2 {
  color: white;
  font-style: italic;
  font-weight: 200;
  font-size: 36px;
}
.navbar-agents1 {
  color: white;
  font-style: regular;
  font-weight: 500;
  font-size: 16px;
}
.navbar-company3 {
  color: white;
  font-style: italic;
  font-weight: 100;
  font-size: 10px;
  text-decoration: #EAEAEA;
}

.footer-link {
  color: #FFFFFF;
  font-weight: 600;
  font-size: 18px;
  text-decoration: none;
}

.nav-link {
  color: #ffffff;
  /* font-weight: 300; */
  /* font-size: 28px;
  line-height: 33px;
  margin-right: 30px;
  margin-left: 30px; */
}

.text-white-thin {
  color: #ffffff;
  font-weight: 300;
  /* font-size: 28px;
  line-height: 33px; */
}

.button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  padding: 0px 30px;
  gap: 10px;

  color: #ffffff;
  height: 40px;

  background: #d74a49;
  border-radius: 25px;

  font-weight: 300;

  border: none;
  /* font-size: 28px;
  line-height: 33px; */
}

.color-primary {
  color: #183E4B;
}

.bg-hero {
  color: #EAEAEA;
  background-image: url("https://storage.googleapis.com/smartai-stockimage-dev-storage-watermark-na/Environments/20230704/f768d0c3-344a-4828-94ca-aad7818ce2c3.png"); 
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-width: 400px;
}

.bg-banner {
  background-color: #2E2E2E;
  width: 100%;
}

.center-pills {
  display: inline-block; 
}

.text-center {
  text-align: center;
}

.searchBar {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

#searchQueryInput {
    width: 100%;
    height: 2.8rem;
    background: #f5f5f5;
    outline: none;
    border: 1px solid #183E4B;
    border-radius: 1.625rem;
    padding: 0 3.5rem 0 1.5rem;
    font-size: 1rem;
}

#searchQuerySubmit {
  width: 3.5rem;
  height: 2.8rem;
  margin-left: -3.5rem;
  background: none;
  border: none;
  outline: none;
}

#searchQuerySubmit:hover {
  cursor: pointer;
}
